type POI = {
    key: string,
    name: string
}

const poiMap: POI[] = [
    { key: 'solarpanels', name: "Photovoltaïque" },
    { key: 'waterplant', name: "Station d'épuration" },
    { key: 'autistics', name: "Travailleurs autistes" },
    { key: 'orchard', name: "Verger Acroécologie" },
    { key: 'carbonreducing', name: "Baisse des émissions carbone" },
    { key: 'carboncompensating', name: "Compensation des émissions carbone" },
    { key: "ecowrap", name: "Emballages éco-responsables" },
    { key: 'committed', name: "Entreprise engagée"},
    { key: 'vehiclefleet', name: "Flotte de vélos & voitures électriques"},
    { key: "orchardwatering", name: "Irrigation du verger"},
    { key: 'carbonneutralcandy', name: "Confiserie neutre en carbone"},
    { key: 'sustainable', name: "Plan de Performance Durable"}
]

export default poiMap