import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import { useNavigate } from "react-router-dom";
import Store from "../../types/store";

const CellEditButton: React.FC<ICellRendererParams<Store>> = ({ data }) => {
  const navigate = useNavigate();

  return (
    <IconButton onClick={() => navigate(`/viewstore/${data?.Uuid}`)}>
      <Edit />
    </IconButton>
  );
};

export default CellEditButton;
