import { Share } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import Store from "../../types/store";

const CellCustomerLink: React.FC<ICellRendererParams<Store>> = ({ data }) => {
  return (
    <div style={{ height: 50, width: 50 }}>
      <Tooltip title={`${process.env.REACT_APP_FRONTEND_URL}/${data?.Uuid}`} placement={'left'}>
        <IconButton
          size="medium"
          onClick={(e) =>
            window.open(
              `${process.env.REACT_APP_FRONTEND_URL}/${data?.Uuid}`,
              "_blank"
            )
          }
        >
          <Share />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default CellCustomerLink;
