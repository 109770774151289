import { Paper, useTheme } from "@mui/material";

const Root: React.FC = () => {
  const { palette } = useTheme();
  return (
    <Paper
      style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: palette.background.default }}
    >
    </Paper>
  );
};

export default Root;
