import {
  Button,
  Card,
  CardMedia,
  Paper,
  Slide,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { RefObject, useEffect, useRef, useState } from "react";
import { useStore } from "../providers/MainContext";
import ChangeFirstPass from "../components/ChangeFirstPass";
import { Logout } from "@mui/icons-material";

const Login: React.FC = () => {
  const store = useStore()!;
  const { palette } = useTheme();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginLoading, setLoginLoading] = useState(false);
  const [mustChangePassword, setMustChangePassword] = useState(false);
  const [slideIn, setSlideIn] = useState(false);
  const slideRef = useRef(null as null | RefObject<HTMLDivElement>);

  const handleLoading = () => {
    setLoginLoading(true);
    store.user.login(username, password, (err, result) => {
      if (err) {
        setPassword("");
      }
      if (err && err.message === "change_pass") {
        setMustChangePassword(true);
      }
      setLoginLoading(false);
    });
  };
  useEffect(() => {
    if (store.user.session) {
      setPassword("");
      setSlideIn(true);
    } else {
      setSlideIn(false);
    }
  }, [store.user.session]);

  const handleEnvironmentSelection = (env: string) => () => {
    localStorage.setItem("androsrselastenv", env);
    store.layout.mainTransition.navigateWithTransition(`/${env}`);
  };

  return (
    <>
      <Paper
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: palette.background.default,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          overflow: "scroll",
        }}
      >
        <Card
          ref={slideRef as RefObject<HTMLDivElement>}
          sx={{
            position: "relative",
            minWidth: 300,
            maxWidth: "90%",
            width: 500,
            minHeight: 200,
            height: 500,
            margin: 1,
            borderRadius: 3,
            boxShadow: "0 0 30px black",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
          >
            <CardMedia
              component="img"
              height="150"
              image={require("../assets/images/logo.png")}
              sx={{ objectFit: "contain" }}
              alt="green iguana"
            />
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ textAlign: "center" }}
            >
              Andros RSE - Backoffice
            </Typography>
            <Typography
              variant="h6"
              sx={{ textAlign: "center", fontWeight: "normal" }}
            >
              Informations de connexion :
            </Typography>
            <div
              style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
            >
              <div style={{ padding: 15 }}>
                <TextField
                  sx={{ margin: 1 }}
                  name="androsrseuser"
                  fullWidth
                  type="text"
                  variant="standard"
                  label="Nom d'utilisateur"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
                <TextField
                  sx={{ margin: 1 }}
                  name="androsrsepass"
                  fullWidth
                  type="password"
                  variant="standard"
                  label="Mot de passe"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexGrow: 1,
                }}
              >
                <div>
                  <LoadingButton
                    loading={loginLoading}
                    disabled={!username || !password}
                    size="large"
                    variant="contained"
                    color="success"
                    onClick={handleLoading}
                  >
                    S'identifier
                  </LoadingButton>
                </div>
              </div>
            </div>
          </div>
          <Slide
            direction="up"
            in={slideIn}
            container={slideRef.current as any}
            timeout={500}
          >
            <Card
              sx={{
                position: "absolute",
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                style={{ textAlign: "center", marginTop: 20 }}
              >
                Choix de l'environnement
              </Typography>
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                {!store.user.roles.length && (
                  <Typography
                    sx={{
                      textAlign: "center",
                      margin: 4,
                    }}
                  >
                    Problème de configuration d'utilisateur, veuillez contacter
                    le support informatique
                  </Typography>
                )}
                {store.user.roles.includes("admin") && (
                  <Button
                    size="large"
                    variant="contained"
                    onClick={handleEnvironmentSelection("storelist")}
                  >
                    Environnement personnalisé
                  </Button>
                )}
                {store.user.roles.includes("superadmin") && (
                  <Button
                    size="large"
                    variant="contained"
                    onClick={handleEnvironmentSelection("viewstore/generic")}
                  >
                    Environnement Générique
                  </Button>
                )}
              </div>
              <div style={{ textAlign: "right" }}>
                <Button
                  endIcon={<Logout />}
                  onClick={() => store.user.logout()}
                >
                  Se déconnecter
                </Button>
              </div>
            </Card>
          </Slide>
        </Card>
      </Paper>
      <ChangeFirstPass
        username={username}
        show={mustChangePassword}
        setShow={setMustChangePassword}
      />
    </>
  );
};

export default Login;
