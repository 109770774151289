import { Home, Logout, RestartAlt } from "@mui/icons-material";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useStore } from "../providers/MainContext";

const SpeedDialComponent: React.FC<{}> = () => {
  const store = useStore()!;
  const navigate = useNavigate();

  return (
    <SpeedDial
      FabProps={{
        size: "large",
        sx: {
          backgroundColor: "background.paper",
          color: "background.default",
          "&:hover": {
            backgroundColor: "background.paper",
            color: "background.default",
          },
        },
      }}
      color={"red"}
      ariaLabel="speed-dial"
      icon={<Home />}
      sx={{ position: "absolute", right: "2em", bottom: "2em" }}
    >
      {store.user.roles.includes("superadmin") && (
        <SpeedDialAction
          key="changeenv"
          icon={<RestartAlt />}
          tooltipTitle="Changer d'environnement"
          onClick={() => navigate("/login")}
        />
      )}
      <SpeedDialAction
        key="logout"
        icon={<Logout />}
        tooltipTitle="Se déconnecter"
        onClick={() => store.user.logout()}
      />
    </SpeedDial>
  );
};

export default SpeedDialComponent;
