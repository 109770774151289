import { Backdrop, CircularProgress } from "@mui/material";
import { useStore } from "../providers/MainContext";


const MainActivity: React.FC = () => {
  const store = useStore()!;

  return (
    <Backdrop open={store.layout.mainActivity.value} sx={{color: 'background.paper'}}>
      <CircularProgress color={'inherit'} size={90} />
    </Backdrop>
  );
};

export default MainActivity;
