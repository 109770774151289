import { Switch } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import Store from "../../types/store";

const CellToggle: React.FC<
  ICellRendererParams<Store> & { putData: (store: Store) => Promise<void> }
> = ({ data, putData }) => {
  return (
    <Switch
      defaultChecked={data?.Active}
      onChange={(e) => {
        if (data) {
          data.Active = e.target.checked;
          putData(data);
        }
      }}
    />
  );
};

export default CellToggle;
