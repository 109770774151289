import { ICellRendererParams } from "ag-grid-community";
import Store from "../../types/store";

const CellImagePreview: React.FC<ICellRendererParams<Store>> = ({ data }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        style={{
          height: 50,
          maxHeight: "100%",
          aspectRatio: '2.19',
          borderRadius: 8,
          objectFit: 'cover',
          boxShadow: "0 0 5px rgba(0,0,0,0.2)",
        }}
        src={data?.Url}
        alt={`${data?.Name} - Aperçu`}
      />
    </div>
  );
};

export default CellImagePreview;
