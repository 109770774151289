import { ArrowBack, Cancel, Save } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Container,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageDrop, { ImageDropProps } from "../components/ImageDrop";
import getErrorMessageFromError from "../helpers/getErrorMessageFromError";
import poiMap from "../helpers/poiMap";
import { useStore } from "../providers/MainContext";
import Store from "../types/store";
import placeholder from "../assets/images/placeholder.png";

const defaultStore = {
  Name: "",
  Pass: "",
  Url: "",
  POIs: [],
  WhatsNew: [],
  Active: false,
};

const ViewStore: React.FC = () => {
  const navigate = useNavigate();
  const { axios, user, isAuth, layout } = useStore()!;
  const { palette } = useTheme();
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<Store>({
    Name: "",
    Pass: "",
    Url: "",
    POIs: [],
    WhatsNew: [],
    Active: false,
  });
  const params = useParams();
  const [loading, setLoading] = useState({
    cancel: false,
    save: false,
  });
  const [valid, setValid] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);

  // Data
  const getData = async () => {
    try {
      if (user.session?.isValid() && (params.storeUuid || data.Uuid)) {
        const response = await axios.get(
          params.storeUuid === "generic"
            ? "/generic"
            : `/store?uuid=${params.storeUuid || data.Uuid}`
        );
        if (
          !user.roles.includes("superadmin") &&
          params.storeUuid === "generic"
        ) {
          setTimeout(() => {
            navigate(-1);
          }, 3000);
          return layout.alert.showAlert({
            alertColor: "error",
            alertMessage: "Vous essayez d'accéder à une page non autorisée",
            timeout: 5000,
          });
        }
        setLoaded(true);
        setData(response.data as Store);
      } else {
        setData({ ...defaultStore });
      }
    } catch (error) {
      printError(error);
      setTimeout(() => {
        navigate(-1);
      }, 2500);
    }
  };
  const getPresignedPost = async (file: File) => {
    const response = await axios.post("s3upload", {
      type: file.type,
      size: file.size,
    });
    console.log(response);
    return response.data;
  };
  const postS3 = async (file: File) => {
    try {
      setFileLoading(true);
      const postData = await getPresignedPost(file);
      const form = new FormData();
      Object.entries(postData.fields).forEach(([field, value]) => {
        form.append(field, value as string);
      });
      form.append("Content-Type", file.type);
      form.append("file", file);
      const response = await fetch(postData.url, {
        method: "POST",
        body: form,
      });
      if (response.status === 204) {
        setData({ ...data, Url: `${postData.url}/${postData.fields.key}` });
        layout.alert.showAlert({
          alertColor: "success",
          alertMessage:
            "Le logo a correctement été mis à jour, pensez à sauvegarder les changements 💾",
          timeout: 5000,
        });
      }
    } catch (error) {
      printError(error);
    } finally {
      setFileLoading(false);
    }
  };
  const onDrop: ImageDropProps["onDrop"] = (
    acceptedFiles,
    fileRejections,
    event
  ) => {
    if (fileRejections.length) {
      let message = "Erreur :";
      fileRejections[0].errors.forEach((value) => {
        switch (value.code) {
          case "file-invalid-type":
            message += " Fichiers acceptés : Jpeg, Png ;";
            break;
          case "file-too-large":
            message += " La taille du fichier est trop grande (>1Mo) ;";
            break;
          default:
            " Quelque chose s'est mal passé ;";
        }
      });
      message = message.slice(0, -2);
      layout.alert.showAlert({
        alertColor: "error",
        alertMessage: message,
        timeout: 4000,
      });
    }
    if (acceptedFiles.length) {
      postS3(acceptedFiles[0]);
    }
  };

  const putData = async () => {
    try {
      const response = await axios.put(
        params.storeUuid === "generic" ? "/generic" : "/store",
        data
      );
      setData({ ...data, Uuid: response.data.Uuid });
      return true;
    } catch (error) {
      printError(error);
      return false;
    }
  };

  const printError = (error: any) =>
    layout.alert.showAlert({
      alertColor: "error",
      alertMessage: getErrorMessageFromError(error),
      timeout: 5000,
    });

  const checkKey = (key: string, active: boolean) => {
    // if !active, filter the array without the key and setData with this new array
    const array = data.POIs.filter((value) => value !== key);
    if (active) array.push(key);
    setData({ ...data, POIs: array });
    // if active, filter the array without the key, push it and setData with it
  };
  const highlight = (key: string, active: boolean) => {
    // if !active, filter the array without the key and setData with this new array
    const array = data.WhatsNew?.filter((value) => value !== key) || [];
    if (active) array.push(key);
    setData({ ...data, WhatsNew: array });
    // if active, filter the array without the key, push it and setData with it
  };
  const isKeyChecked = (key: string): boolean => {
    return data.POIs.includes(key);
  };
  const isHighlighted = (key: string): boolean => {
    return Boolean(data.WhatsNew?.includes(key));
  };

  // Initial data get
  useEffect(() => {
    if (!loaded && isAuth) {
      if (!params.storeUuid) {
        return setLoaded(true);
      }
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  useEffect(() => {
    // get the image from AWS, show it in the preview TBD
  }, [data?.Url]);

  // Data Validation
  useEffect(() => {
    setValid(!!data.Name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  return (
    <Paper
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: palette.background.default,
        overflow: "auto",
      }}
    >
      <Container
        sx={{
          backgroundColor: "background.paper",
          borderRadius: 1,
          boxShadow: "0 0 30px rgba(0,0,0,0.3)",
          marginTop: 2,
          padding: 2,
          position: "relative",
        }}
      >
        {}
        <Fab
          style={{ position: "absolute", top: "1em", left: "1em" }}
          onClick={(e) => navigate(-1)}
        >
          <ArrowBack />
        </Fab>
        <AnimatePresence initial={false} presenceAffectsLayout={false}>
          {!loaded && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                position: "absolute",
                zIndex: 9999,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: palette.background.paper,
              }}
            >
              <CircularProgress size={80} />
            </motion.div>
          )}
        </AnimatePresence>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div
            style={{
              width: 550,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <LoadingButton
              loading={loading.cancel}
              style={{ margin: 2 }}
              onClick={async () => {
                try {
                  setLoading({ ...loading, cancel: true });
                  await getData();
                  layout.alert.showAlert({
                    alertColor: "info",
                    alertMessage: "Données remises à zéro",
                    timeout: 3500,
                  });
                } catch (error) {
                  printError(error);
                } finally {
                  setLoading({ ...loading, cancel: false });
                }
              }}
            >
              <Cancel color="action" />
            </LoadingButton>
            <Typography
              variant="h6"
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              {data && data.Uuid ? data.Name : "Création"}
            </Typography>
            <LoadingButton
              loading={loading.save}
              style={{ margin: 2 }}
              onClick={async () => {
                try {
                  if (valid) {
                    setLoading({ ...loading, save: true });
                    if (await putData()) {
                      layout.alert.showAlert({
                        alertColor: "success",
                        alertMessage: "Modifications enregistrées avec succès",
                        timeout: 3500,
                      });
                    }
                  } else {
                    layout.alert.showAlert({
                      alertColor: "warning",
                      alertMessage:
                        "Assurez-vous d'avoir correctement configuré le client",
                      timeout: 3500,
                    });
                  }
                } catch (error) {
                  printError(error);
                } finally {
                  setLoading({ ...loading, save: false });
                }
              }}
            >
              <Save color="success" />
            </LoadingButton>
          </div>
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2} maxWidth={800}>
            <Grid item xs={12}>
              {(data?.Uuid && (
                <Typography variant="body2" align="justify">
                  Vous pouvez ci-dessous modifier l'entrée client du magasin{" "}
                  {data.Name}.
                  <br />
                  Activez ou désactivez les points d'intérêts visibles sur la
                  version de la carte présentée au client, <br />
                  Renommez le, changez son logo, activez ou désactiver la
                  validité du lien sans supprimer l'entrée...
                </Typography>
              )) || (
                <Typography variant="subtitle2" align="justify">
                  Créer ci-dessous une entrée client
                  <br /> Une fois l'entrée créée, visualisez-là dans la liste ou
                  copiez le lien fourni
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
            >
              <ImageDrop
                onDrop={onDrop}
                url={data.Url || placeholder}
                loading={fileLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <div>
                <TextField
                  label={"Nom du client"}
                  fullWidth
                  value={data?.Name}
                  onChange={(e) => {
                    setData({ ...data, Name: e.target.value });
                  }}
                />
                {data.Uuid !== "generic" && (
                  <div>
                    <TextField
                      label={"E-Mail d'accès"}
                      fullWidth
                      style={{ marginTop: "1em" }}
                      value={data?.Pass}
                      onChange={(e) => {
                        setData({ ...data, Pass: e.target.value });
                      }}
                    />
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography>Client actif : </Typography>
                <Switch
                  checked={data.Active}
                  onChange={(e) => {
                    setData({ ...data, Active: e.target.checked });
                  }}
                />
              </div>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" align="justify">
                Activez ou désactivez les points d'intérêts visibles à tout
                moment sur la carte à l'aide des commutateurs ci-dessous
              </Typography>
            </Grid>
            {poiMap.map((poi) => (
              <Grid item key={poi.key} xs={12} md={6}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography textAlign={"left"}>{poi.name}</Typography>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={isKeyChecked(poi.key)}
                        onChange={(e) => {
                          return checkKey(poi.key, e.target.checked);
                        }}
                      />
                    }
                    label="Activé"
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={isHighlighted(poi.key)}
                        onChange={(e) => {
                          return highlight(poi.key, e.target.checked);
                        }}
                      />
                    }
                    label="Mis en avant"
                  />
                </div>
                <hr />
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </Paper>
  );
};

export default ViewStore;
