import { CircularProgress, Typography } from "@mui/material";
import Dropzone, { DropEvent, FileRejection } from "react-dropzone";
import "../styles/App.css";

export type ImageDropProps = {
  onDrop: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  url: string;
  loading: boolean;
};

const acceptedMimes = {
  "image/png": [".png"],
  "image/jpg": [".jpg", ".jpeg"],
};

const ImageDrop: React.FC<ImageDropProps> = ({ onDrop, url, loading }) => {
  return (
    <Dropzone
      onDrop={onDrop}
      accept={acceptedMimes}
      multiple={false}
      maxSize={parseInt(process.env.REACT_APP_MAX_SIZE!)}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          {...getRootProps()}
          style={{
            width: 550,
            maxWidth: "100%",
            boxShadow: "0px 0px 20px rgba(0,0,0,0.1)",
            padding: 10,
            borderRadius: 10,
            position: "relative",
          }}
        >
          <input {...getInputProps()} />
          <img
            src={url}
            style={{
              aspectRatio: "2.19",
              objectFit: "cover",
              objectPosition: "center center",
              width: "100%",
              borderRadius: 5,
            }}
          />
          {isDragActive && (
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="backdrop"
            >
              <Typography
                style={{ padding: 10, textAlign: "center" }}
                fontWeight={"bold"}
              >
                Vous pouvez relâcher 👍
              </Typography>
            </div>
          )}
          {loading && (
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
              className="backdrop"
            >
              <div style={{ color: "white" }}>
                <CircularProgress color="inherit" />
              </div>
              <Typography
                style={{ padding: 10, textAlign: "center" }}
                fontWeight={"bold"}
              >
                Chargement de votre image en cours ⏳
              </Typography>
            </div>
          )}
          <Typography color={"primary.light"} style={{ padding: 10 }}>
            Faites glisser une image ou cliquez dans la zone pour choisir un
            nouveau logo client.
          </Typography>
        </div>
      )}
    </Dropzone>
  );
};

export default ImageDrop;
