import { AxiosError } from "axios"

const getErrorMessageFromError = (error: any): string => {
    console.log(error)
    if (error instanceof String) {
        return String(error)
    }
    if (!(error instanceof Error)) {
        return "Une erreur est survenue"
    }
    switch (error.name) {
        case "NotAuthorizedException":
            switch (error.message) {
                case "Incorrect username or password.":
                    return "Utilisateur ou mot de passe incorrect"
                case "User is disabled.":
                    return "L'utilisateur est desactivé"
                case "Password attempts exceeded":
                    return "Trop d'essais manqués, contactez le support informatique"
                default:
                    return "Non autorisé"
            }
        case "InvalidPasswordException":
            if (error.message.includes("Password not long enough")) {
                return "Mot de passe trop court"
            }
            if (error.message.includes("Password must have lowercase characters")) {
                return "Le mot de passe doit contenir une ou des minuscules"
            }
            if (error.message.includes("Password must have uppercase characters")) {
                return "Le mot de passe doit contenir une ou des majuscules"
            }
            if (error.message.includes("Password must have numeric characters")) {
                return "Le mot de passe doit contenir un ou plusieurs chiffres"
            }
            if (error.message.includes("Password must have symbol characters")) {
                return "Le mot de passe doit contenir un ou plusieurs caractères spéciaux"
            }
            return "Le mot de passe n'est pas satisfaisant"
        case "AxiosError":
            if (error instanceof AxiosError) {
                if (error.response?.status === 401) {
                    return "Vous n'avez pas le droit d'accéder à cette ressource"
                }
                if (error.response?.status === 404) {
                    return "L'objet de la requête est introuvable"
                }
            }
            return "Un problème réseau est survenu"
        default:
            return "Une erreur est survenue"
    }
}

export default getErrorMessageFromError