import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useStore } from "../providers/MainContext";

type ChangeFirstPassProps = {
  show: boolean;
  username: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChangeFirstPass: React.FC<ChangeFirstPassProps> = ({
  show,
  setShow,
  username,
}) => {
  const store = useStore()!;

  const [newPass, setNewPass] = useState("");
  const [newPassRepeat, setNewPassRepeat] = useState("");
  const [loading, setLoading] = useState(false);

  const handleNewPass = () => {
    setLoading(true);
    store.user.changeFirstPassword(username, newPass, (err) => {
      if (!err) {
        setNewPass("");
        setNewPassRepeat("");
        setLoading(false);
        setShow(false);
      }
      setLoading(false)
    });
  };

  return (
    <Dialog open={show} onClose={() => setShow(false)}>
      <DialogTitle id="alert-dialog-title">Première Connexion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Vous vous connectez pour la première fois à l'application backoffice
          Andros-RSE. <br />
          Afin de garantir votre sécurité, vous devez changer votre mot de passe
          :
        </DialogContentText>
        <TextField
          sx={{ margin: 1 }}
          name="androsrsenewpass"
          fullWidth
          type="password"
          variant="standard"
          label="Nouveau mot de passe"
          onChange={(e) => setNewPass(e.target.value)}
        />
        <TextField
          sx={{ margin: 1 }}
          name="androsrsemewpassrepeat"
          fullWidth
          type="password"
          variant="standard"
          label="Répétez votre mot de passe"
          onChange={(e) => setNewPassRepeat(e.target.value)}
        />
        <div style={{ textAlign: "center" }}>
          <LoadingButton
            disabled={!newPass || newPass !== newPassRepeat}
            loading={loading}
            color="success"
            onClick={handleNewPass}
          >
            Changer le mot de passe
          </LoadingButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeFirstPass;
