import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import Store from "../../types/store";

const CellDeleteButton: React.FC<
  ICellRendererParams<Store> & { deleteData: (store: Store) => Promise<void> }
> = ({ data, api, deleteData, rowIndex }) => {
  const deleteItem = async () => {
    if (data) {
      await deleteData(data);
      api.applyTransaction({ remove: [data] });
    }
  };

  return (
    <IconButton onClick={deleteItem}>
      <Delete />
    </IconButton>
  );
};

export default CellDeleteButton;
