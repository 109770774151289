import { Routes, Route } from "react-router-dom";
import { useStore } from "../providers/MainContext";
import Login from "./Login";
import Root from "./Root";
import StoreList from "./StoreList";
import ViewStore from "./ViewStore";

const App: React.FC<{}> = () => {
  const store = useStore()!;
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Root />} />
        <Route path="/login" element={<Login />} />
        {store.user.session && (
          <>
            <Route path="/viewstore">
              <Route path="" element={<ViewStore />} />
              <Route path=":storeUuid" element={<ViewStore />} />
            </Route>
            <Route path="/storelist" element={<StoreList />} />
          </>
        )}
        <Route path="*" element={<Root />} />
      </Routes>
    </div>
  );
};

export default App;
