/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useRef, useState } from "react";
import MainActivity from "../components/MainActivity";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { useLocation, useNavigate } from "react-router-dom";
import AlertSlide, { AlertSlideRef } from "../components/AlertSlide";
import getErrorMessageFromError from "../helpers/getErrorMessageFromError";
import MainTransition, {
  MainTransitionRef,
} from "../components/MainTransition";
import SpeedDialComponent from "../components/SpeedDialComponent";
import Axios, { AxiosInstance } from "axios";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

// AWS Cognito
const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USERPOOLID!,
  ClientId: process.env.REACT_APP_CLIENTID!,
});

// MUI
const theme = createTheme({
  palette: {
    primary: {
      main: "#282c34",
    },
    background: {
      paper: "#eaeaef",
      default: "#282c34",
    },
  },
});

type MainStoreProps = {
  children: React.ReactElement;
};
type MainStoreContextType = {
  axios: AxiosInstance;
  isAuth: boolean;
  layout: {
    mainActivity: {
      value: boolean;
      set: React.Dispatch<React.SetStateAction<boolean>>;
    };
    alert: AlertSlideRef;
    mainTransition: MainTransitionRef;
  };
  user: {
    session: CognitoUserSession | null;
    roles: string[];
    login: (
      username: string,
      password: string,
      callback?: (err: Error | null, result: any | null) => void
    ) => Promise<void>;
    logout: () => void;
    changeFirstPassword: (
      username: string,
      password: string,
      callback?: (err: Error | null, result: any | null) => void
    ) => Promise<void>;
  };
};

const MainStoreContext = createContext(null as null | MainStoreContextType);
export const useStore = () => useContext(MainStoreContext);

const MainStore: React.FC<MainStoreProps> = ({ children }) => {
  // Router and networking
  const navigate = useNavigate();
  const location = useLocation();
  // Router and networking
  const [isAuth, setIsAuth] = useState(false);

  // Display & Layout Store
  const [openMainActivityIndicator, setOpenMainActivityIndicator] =
    useState(true);
  const alert = useRef(null as null | AlertSlideRef);
  const mainTransition = useRef(null as null | MainTransitionRef);
  // Display & Layout Store

  // User Session Store
  const [user, setUser] = useState(null as null | CognitoUser);
  const [session, setSession] = useState(null as null | CognitoUserSession);
  const [sessionRoles, setSessionRoles] = useState<string[]>([]);
  const [sessionRolesTimeout, setSessionRolesTimeout] =
    useState<NodeJS.Timeout>();
  useEffect(() => {
    // try to retrieve the user session
    const username = localStorage.getItem("androsrsebackofficeusername");
    if (username) {
      // create the user, try to get their session
      const tmpUser = new CognitoUser({
        Pool: userPool,
        Username: username,
      });
      tmpUser.getSession(
        (err: Error | null, userSession: null | CognitoUserSession) => {
          if (userSession && userSession.isValid()) {
            localStorage.setItem("androsrsebackofficeusername", username);
            setSession(userSession);
            setUser(tmpUser);
            // if we're at root
            if (location.pathname === "/") {
              // we try to navigate to our last environment
              const lastEnv = localStorage.getItem("androsrselastenv");
              if (lastEnv) {
                navigate(`/${lastEnv}`);
              } else {
                // we navigate to login, as we're logged in, it will display the environment selection page
                navigate(`/login`);
              }
            }
            setOpenMainActivityIndicator(false);
          } else {
            setOpenMainActivityIndicator(false);
            navigate("/login");
          }
        }
      );
    } else {
      setOpenMainActivityIndicator(false);
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    if (session && session.isValid()) {
      if (sessionRolesTimeout) {
        clearTimeout(sessionRolesTimeout);
        setSessionRolesTimeout(undefined);
      }
      // we extract the user roles
      const tmpRoles = session.getIdToken().decodePayload()[
        "custom:roles"
      ] as string;
      if (tmpRoles) {
        setSessionRoles(tmpRoles.split(","));
      }
      setIsAuth(true);
      axios.defaults.headers.common.authorization = session
        .getIdToken()
        .getJwtToken();
    } else {
      setSessionRolesTimeout(setTimeout(() => {}, 800));
      setIsAuth(false);
      axios.defaults.headers.common.authorization = "";
      setUser(null);
    }
  }, [session]);
  // User Session Store

  // User Methods
  const login = async (
    username: string,
    password: string,
    callback?: (err: Error | null, result: any | null) => void
  ) => {
    // create a cognito user and logging them
    const tmpUser = new CognitoUser({
      Pool: userPool,
      Username: username,
    });
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
    tmpUser.authenticateUser(authenticationDetails, {
      onSuccess: (userSession) => {
        setSession(userSession);
        setUser(tmpUser);
        localStorage.setItem("androsrsebackofficeusername", username);
        if (callback) {
          callback(null, null);
        }
      },
      onFailure: (err) => {
        const alertMessage = getErrorMessageFromError(err);
        alert.current?.showAlert({
          alertColor: "error",
          alertMessage,
          timeout: 4000,
        });
        if (callback && err instanceof Error) {
          callback(err, null);
        }
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        setUser(tmpUser);
        if (!requiredAttributes.length && callback) {
          callback(new Error("change_pass"), null);
        }
      },
    });
  };
  const logout = () => {
    localStorage.removeItem("androsrsebackofficeusername");
    setSession(null);
    setUser(null);
    setIsAuth(true);
    axios.defaults.headers.common.authorization = "";
    navigate("/login");
  };
  const changeFirstPassword = async (
    username: string,
    password: string,
    callback?: (err: Error | null, result: any | null) => void
  ) => {
    if (user) {
      user.completeNewPasswordChallenge(password, [], {
        onFailure: (err) => {
          const alertMessage = getErrorMessageFromError(err);
          alert.current?.showAlert({
            alertColor: "error",
            alertMessage,
            timeout: 4000,
          });
          if (callback && err instanceof Error) {
            callback(err, null);
          }
        },
        onSuccess: (userSession) => {
          setSession(userSession);
          localStorage.setItem("androsrsebackofficeusername", username);
          if (callback) {
            callback(null, null);
          }
        },
      });
    }
  };
  // User Methods

  return (
    <>
      <ThemeProvider theme={theme}>
        <MainStoreContext.Provider
          value={{
            axios,
            isAuth,
            layout: {
              mainActivity: {
                value: openMainActivityIndicator,
                set: setOpenMainActivityIndicator,
              },
              alert: alert.current!,
              mainTransition: mainTransition.current!,
            },
            user: {
              session,
              roles: sessionRoles,
              changeFirstPassword,
              login,
              logout,
            },
          }}
        >
          {children}
          <MainActivity />
          <AlertSlide ref={alert} />
          <MainTransition ref={mainTransition} />
          {session &&
            location.pathname !== "/" &&
            location.pathname !== "/login" && <SpeedDialComponent />}
        </MainStoreContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default MainStore;
