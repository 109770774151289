import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  useTheme,
  Fab,
} from "@mui/material";
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useStore } from "../providers/MainContext";
import Store from "../types/store";
import { Add } from "@mui/icons-material";
import getErrorMessageFromError from "../helpers/getErrorMessageFromError";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { ColDef } from "ag-grid-community";

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import CellImagePreview from "../components/AgGrid/CellImagePreview";
import CellToggle from "../components/AgGrid/CellToggle";
import CellEditButton from "../components/AgGrid/CellEditButton";
import CellDeleteButton from "../components/AgGrid/CellDeleteButton";
import CellCustomerLink from "../components/AgGrid/CellCustomerLink";

const StoreList: React.FC = () => {
  const { axios, user, isAuth, layout } = useStore()!;
  const { palette } = useTheme();
  const [data, setData] = useState<Store[]>([]);
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();

  // Data
  const getData = async () => {
    try {
      if (user.session?.isValid()) {
        const response = await axios.get("/storelist");
        setLoaded(true);
        setData(
          (response.data as Store[]).sort((a, b) => {
            if (a.CreationDate && b.CreationDate) {
              return (
                new Date(b.CreationDate).getTime() -
                new Date(a.CreationDate).getTime()
              );
            }
            return 0;
          })
        );
      }
    } catch (error) {
      layout.alert.showAlert({
        alertColor: "error",
        alertMessage: getErrorMessageFromError(error),
        timeout: 5000,
      });
    } finally {
      setLoaded(true);
    }
  };
  const putData = async (store: Store) => {
    try {
      await axios.put("/store", store);
    } catch (error) {
      layout.alert.showAlert({
        alertColor: "error",
        alertMessage: getErrorMessageFromError(error),
        timeout: 5000,
      });
    }
  };
  const deleteData = async (store: Store) => {
    try {
      await axios.delete("/store", { params: { uuid: store.Uuid } });
    } catch (error) {
      layout.alert.showAlert({
        alertColor: "error",
        alertMessage: getErrorMessageFromError(error),
        timeout: 5000,
      });
    }
  };
  useEffect(() => {
    if (!loaded && isAuth) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  // Each Column Definition results in one Column.
  const [defaultColDef] = useState<ColDef>({ resizable: true, cellStyle: {textAlign: 'left'} });
  const [columnDefs] = useState<ColDef[]>([
    {
      field: "Name",
      headerName: "Nom du client",
      filter: true,
      flex: 2,
      sortable: true,
    },
    {
      field: "Url",
      headerName: "Aperçu",
      width: 150,
      cellRenderer: CellImagePreview,
      cellStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    {
      field: "CreationDate",
      headerName: "Créé le",
      sortable: true,
      width: 180,
      valueFormatter: ({ value }) => {
        const date = new Date(value);
        return `${date.toLocaleDateString("fr-FR")} à ${date.toLocaleTimeString(
          "fr-FR",
          { hour: "2-digit", minute: "2-digit" }
        )}`;
      },
    },
    {
      field: "Uuid",
      headerName: "Lien Client",
      width: 110,
      resizable: false,
      cellRenderer: CellCustomerLink,
      cellStyle: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "Active",
      headerName: "Lien activé",
      width: 110,
      resizable: false,
      cellRendererParams: {
        putData,
      },
      cellRenderer: CellToggle,
      cellStyle: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "Uuid",
      headerName: "Éditer",
      width: 75,
      resizable: false,
      cellRenderer: CellEditButton,
      cellStyle: {
        textAlign: "center",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
      },
    },
    {
      field: "Uuid",
      headerName: "Supprimer",
      width: 110,
      resizable: false,
      cellRenderer: CellDeleteButton,
      cellRendererParams: {
        deleteData,
      },
      cellStyle: {
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
      },
    },
  ]);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: palette.background.default,
        overflow: "auto",
      }}
    >
      <Container
        sx={{
          backgroundColor: "background.paper",
          borderRadius: 1,
          boxShadow: "0 0 30px rgba(0,0,0,0.3)",
          marginTop: 2,
          padding: 2,
          position: "relative",
        }}
      >
        <AnimatePresence initial={false} presenceAffectsLayout={false}>
          {!loaded && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              style={{
                position: "absolute",
                zIndex: 9999,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: palette.background.paper,
              }}
            >
              <CircularProgress size={80} />
            </motion.div>
          )}
        </AnimatePresence>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Vos magasins configurés</Typography>
            <Divider />
            <Typography variant="subtitle1">
              Retrouvez ici les magasins clients configurés.
              <br /> Chaque magasin dispose d'un lien pouvant être fourni au
              client pour visualiser son expérience personnalisée
            </Typography>
          </Grid>
        </Grid>
        <div
          className="ag-theme-alpine"
          style={{
            width: "100%",
            height: 500,
            marginTop: 20,
            overflow: "hidden",
            marginBottom: 20,
          }}
        >
          <AgGridReact
            animateRows={true}
            rowHeight={60}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            rowData={data}
          />
        </div>
        <Divider />
        <div style={{ textAlign: "right", marginTop: "1.5em" }}>
          <Fab color="info" onClick={() => navigate("/viewstore")}>
            <Add />
          </Fab>
        </div>
      </Container>
    </div>
  );
};

export default StoreList;
