import {
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import "./main-transition.style.scss";

export type MainTransitionRef = {
  navigateWithTransition: (route: string) => void;
};

const MainTransition: ForwardRefRenderFunction<{}> = (props, ref) => {
  const navigate = useNavigate();
  const [inProp, setInProp] = useState(false);
  const [zIndex, setZIndex] = useState(-9999);

  useImperativeHandle(ref, () => ({
    navigateWithTransition: (route: string) => {
      setInProp(true);
      setZIndex(9999);
      setTimeout(() => {
        navigate(route);
        setInProp(false);
        setTimeout(() => {
          setZIndex(-9999);
        }, 400);
      }, 500);
    },
  }));

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: zIndex,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CSSTransition
          addEndListener={() => {}}
          in={inProp}
          timeout={600}
          unmountOnExit
          classNames="main-transition"
        >
          <div className="main-transition-initial"></div>
        </CSSTransition>
      </div>
    </>
  );
};

export default forwardRef(MainTransition);
