import { Alert, AlertColor, Slide, SlideProps, Snackbar } from "@mui/material";
import { forwardRef, Ref, useImperativeHandle, useState } from "react";

export type AlertSlideRef = {
  showAlert: (config: {
    alertColor: AlertColor;
    alertMessage: string;
    timeout: number;
  }) => void;
};

type AlertSlideProps = {
  ref: Ref<AlertSlideRef>;
};

function SlideTransition(props: SlideProps) {
  return <Slide {...props} timeout={600} direction="up" />;
}

const AlertSlide: React.ForwardRefRenderFunction<
  AlertSlideRef,
  AlertSlideProps
> = (props, ref) => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState<AlertColor>("info");
  useImperativeHandle(ref, () => ({
    showAlert: ({ alertColor, alertMessage, timeout }) => {
      setColor(alertColor);
      setMessage(alertMessage);
      setShow(true);
      setTimeout(() => setShow(false), timeout);
    },
  }));

  return (
    <Snackbar
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      autoHideDuration={6000}
      open={show}
      onClose={() => setShow(false)}
      TransitionComponent={SlideTransition}
    >
      <Alert severity={color} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default forwardRef(AlertSlide);
